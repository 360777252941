<template>
    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <b-row>
            <b-col lg="12">
                <div class="post" v-if="error">
                    <div v-if="error" class="error">
                        {{ error }}
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row class="match-height" v-if="dataLoaded">
            <b-col lg="12">
                <b-card>
                    <b-card-body>

                        <div class="d-flex justify-content-between flex-wrap">
                            <b-col lg="8">

                            </b-col>
                            <b-col lg="4">

                                <b-form-group label="Search"
                                              label-cols-sm="3"
                                              label-align-sm="right"
                                              label-size="sm"
                                              label-for="SearchInput"
                                              class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input id="SearchInput"
                                                      v-model="SearchText"
                                                      type="text"
                                                      placeholder="Type to Search"
                                                      @keyup="searchContact()" />
                                        <b-input-group-append>
                                            <b-button :disabled="!SearchText" @click="clearSearchField">
                                                Clear
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>


                            </b-col>
                        </div>


                    </b-card-body>

                    <b-table striped
                             bordered
                             hover
                             responsive
                             fixed
                             class="vgt-table bordered text-center"
                             :current-page="currentPage"
                             :items="users"
                             :fields="fields"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             @filtered="onFiltered"
                             show-empty>
                        <template #empty="scope">
                            Record Not Found
                        </template>
                        <template #cell(actions)="data">
                            <b-button size="sm"
                                      title="Edit"
                                      v-b-modal.editContact
                                      type="button"
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      v-b-tooltip.hover.v-info
                                      variant="flat-info"
                                      class="btn-icon"
                                      @click="showEditModal(data.item.Id,data.item.Email,data.item.PrimaryEmailId,data.item.ContactSubscriberId,data.item.FirstName,data.item.LastName,data.item.ProjectId,data.item.IsSubscribed)">
                                <feather-icon icon="EditIcon" size="13" />
                            </b-button>

                            <b-button title="Projects"
                                      size="sm"
                                      class="btn-icon"
                                      v-b-modal.projectmodal
                                      type="button"
                                      v-b-tooltip.hover.v-primary
                                      variant="flat-primary"
                                      @click="showProject(data.item.Id)">
                                <feather-icon icon="PackageIcon" size="13" />
                            </b-button>
                        </template>
                        <template #cell(date)="data">
                            <span>
                                {{data.item.RecCreatedDt | moment("DD-MMM-YYYY hh:mm:ss A")}}
                            </span>
                        </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                        <b-form-group label="Show"
                                      label-cols="4"
                                      label-align="center"
                                      label-size="sm"
                                      label-for="sortBySelect"
                                      class="text-nowrap mb-md-0 mr-1">
                            <b-form-select id="perPageSelect"
                                           v-model="perPage"
                                           size="sm"
                                           @change="searchContact()"
                                           inline
                                           :options="pageOptions" />
                        </b-form-group>
                        <div v-if="totalRows > perPage">
                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                <b-form-group label="Show"
                                              label-cols="4"
                                              label-align="center"
                                              label-size="sm"
                                              label-for="sortBySelect"
                                              class="text-nowrap mb-md-0 mr-1">
                                    <b-form-select id="perPageSelect"
                                                   v-model="perPage"
                                                   size="sm"
                                                   @change="searchContact()"
                                                   inline
                                                   :options="pageOptions" />
                                </b-form-group>
                                <div>
                                    <b-pagination v-model="currentPage"
                                                  :total-rows="totalRows"
                                                  :per-page="perPage"
                                                  first-number
                                                  last-number
                                                  prev-class="prev-item"
                                                  next-class="next-item"
                                                  class="mb-0"
                                                  @change="loadPage">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </b-card-body>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

    </b-overlay>
</template>

<script>
    import BCardCode from '@core/components/b-card-code'
    import Ripple from "vue-ripple-directive";


    import {
        BOverlay,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BCardBody,
        BForm,
        BRow,
        BCol,
        BLink,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
    } from 'bootstrap-vue'
    import { codeKitchenSink } from "../../table/bs-table/code.js";
    import jwt from "@/auth/jwt/useJwt";
    import { $themeConfig } from "@themeConfig";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import { extend } from 'vee-validate';

    //import axios from 'axios'
    //import qs from "qs"
    /*import { codeTypes } from './code'*/

    export default {
        components: {
            BCard,
            Ripple,
            Multiselect,
            BOverlay,
            BCardCode,
            BForm,
            BRow,
            BCol,
            BTable,
            ValidationProvider,
            ValidationObserver,
            BAvatar,
            BBadge,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCardBody,
            BLink,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                isModalVisible: false,
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",
                WebAPIURL: $themeConfig.app.apiURL,
                error: null,
                dataLoaded: false,
                pageArr: [],
                users: [],
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                SearchText: null,
                filterOn: [],
                fields: [
                    { key: "FirstName", label: "Name", sortable: true },
                    { key: "Email", label: "Email", sortable: true },
                    { key: "ContactsCount", label: "Contacts", sortable: true },
                    { key: "EmailsSentCount", label: "Emails", sortable: true },
                    { key: "RecCreatedDt", label: "Created On", sortable: true },
                    { key: "UserIsActive", label: "Is Active?", sortable: true },
                    { key: "IsDisplayCanSpanLogo", label: "Is Display CanSpam Logo?", sortable: true },
                    "Actions",
                ],
                userResultCount: 0,

                meta: {
                    pageTitle: 'Users',
                    breadcrumb: [
                        {
                            text: 'Users',
                            active: true
                        },
                    ],
                },
                pageInfo: [
                    {
                        pageSize: 10,
                        pageNumber: 0,
                    }
                ]
            }
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            //var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            this.fetchUsersData(this.pageInfo)
            //}
        },
        beforeCreate() {
            
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchUsersData'
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
            },
        },
        mounted() {

            this.fetchUsersData(1);
        },
        methods: {

            searchContact() {
                this.fetchUsersData(1);
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                //this.totalRows = filteredItems.length
                //this.currentPage = 1
            },
            clearSearchField() {
                this.SearchText = '';
            },
            fetchUsersData: async function (pagenumber) {
                this.currentPage = pagenumber;
                var self = this;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;

                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };

                axios
                    .get("" + this.WebAPIURL + "/api/ManageUsers1257861/SearchUsers", { headers })
                    .then(function (response) {

                        self.users = response.data;
                        var i;
                        for (i = 0; i < self.users.length; i++) {
                            self.users[i].RecCreatedDt = moment(self.users[i].RecCreatedDt).format("DD-MMM-YYYY hh:mm:ss A");
                        }

                        if (response.data.length > 0) {
                            self.totalRows = response.data[0].TotalCount;
                        } else {
                            self.totalRows = 0;
                        }
                        self.modalShow = true;
                        self.loading = false;
                        self.dataLoaded = true;
                       // console.log("response.data:: " + response.data);
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        self.dataLoaded = true;
                       // console.log("error:: " + error);
                    });
            },
        }
    }
</script>
